import { SubMenuItems } from 'design-system/domain';

export const subMenuItems: SubMenuItems[] = [
    {
      link: "https://conecta.webpremios.digital/extract",
      title: "Minha Conta"
    },
    {
      link: "https://conecta.webpremios.digital/extract",
      title: "Extrato"
    },
    {
      link: `${process.env.REACT_APP_CONECTA_AG_LOGOUT_URL}`,
      title: "Sair"
    }
  ]